html body {
  font-family: sans-serif;
}

a {
  font-family: sans-serif;
}
a.b {
  font-family: sans-serif;
}

h1 h2 h3 h4 h5 h5 p b ul li span  {
  font-family: sans-serif;
}

@media (min-width: 1200px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  

}
@media (min-width: 1200px) and (max-width: 1400px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }

  /* .mobiiconapp{
    border: 1px solid white;
    border-radius: 5px;
    scale: 4.5!important;
    margin-left: 40px;
  } */
  

}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {
  /* .mobiiconapp{
    border: 1px solid white;
    border-radius: 5px;
    scale: 4.5;
    margin-left: 40px;
  } */
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  
}

 
/* small mobile :320px. */
@media (max-width: 767px) {
	html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  
}
 
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  html body {
    font-family: sans-serif;
  }
  
  a {
    font-family: sans-serif;
  }
  a.b {
    font-family: sans-serif;
  }
  
  h1 h2 h3 h4 h5 h5 p b ul li span  {
    font-family: sans-serif;
  }
  

}
 



