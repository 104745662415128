/* body {
    margin: 20px;
  } */
  
  /* .container {
    text-align: center;
  } */
  @media only screen and (max-device-width: 530px) {
    img.top {
      padding-top: 6px!important;
    }
  }
  
  .mockup {
    display: inline-block;
    position: relative;
    z-index: 3;
    text-align: center;
    font-size: 0;
    perspective: 2400px;
    perspective-origin: 50% 100%;
    opacity: 0;
    transition: 500ms opacity;
  }
  
  .mockup.loaded {
    opacity: 1;
  }
  
  .mockup .part .top,
  .mockup .part .bottom {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .mockup .part.top {
    transform: translate3d(0, 0, 0) rotateX(-90deg);
  }
  
  .mockup:hover .part.top {
    transform: translate3d(0, 0, 0) rotateX(0deg);
  }
  
  .mockup .part {
    display: inline-block;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform-origin: 50% 100%;
    transition: 900ms;
  }
  
  .mockup.opened .part .top {
    transform: translate3d(0, 0, -11px) rotateX(90deg) scale(1, 1);
  }
  
  .mockup .part .top {
    transform-origin: 50% 0;
    transform: translate3d(0, 0, -11px) rotateX(90deg);
    transition: 900ms;
  }
  
  .mockup img {
    display: block;
    max-width: 100%;
    backface-visibility: hidden;
  }
  
  .mockup .part .cover {
    position: relative;
  }
  
  .mockup video {
    display: block;
    position: absolute;
    top: 8%;
    left: 1%;
    width: 98%;
    border-radius: 6px;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 1px);
  }
  
  .mockup .part.bottom {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0) rotateX(-90deg);
  }
  
  .mockup .part .bottom {
      transform-origin: 50% 0;
      transform: translate3d(0,0,0) rotateX(90deg);
  }


  .laptext {
    /* position: relative; */
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 4px;
    text-align: center;
    overflow: hidden;
    background: linear-gradient(90deg, #000, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 3s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    position: absolute;
    top: 100px; 
     left: 200px;
  }
  
  @keyframes animate {
    0% {
      background-position: -500%;
    }
    100% {
      background-position: 500%;
    }
  }

  @media only screen and (max-device-width: 530px){
    p.laptext.aos-init.aos-animate{
        top: 60px;
        left: 73px;
    }
  }
  #videoContainer {
    width: 100%;
    height: 100vh; /* Adjust as needed */
    position: relative;
    overflow: hidden;
  }

