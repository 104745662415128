@media only screen and (max-device-width: 530px) {
  .btnloginmobi {
    border: 1px solid white !important;
    border-radius: 10px !important;
    font-size: 18px !important;
    margin-left: 70px !important;
    margin-right: 118px !important;
  }
  .btnbanner{
    margin-right: 2%!important;
  }
  .mobibanner {
    scale: 1.1 !important;
    margin-top: -90px !important;
    margin-left: 0px !important;
    width: fit-content;
  }
  .bannername {
    text-align: center !important;
    text-transform: capitalize !important;
    letter-spacing: 2px !important;
    font-size: 34px !important;
    font-family: sans-serif !important;
    margin-left: 10px !important;
    margin-top: 24px !important;
    font-weight: 900 !important;
  }
  .dpmobifoter {
    display: flex !important;
  }
  .foterimgmobi {
    scale: 1.3;
  }
  .fotertxtsubsmobi {
    font-size: 19px !important;
    color: rgb(255, 255, 255) !important;
    padding: 8px !important;
    margin-top: -35px !important;
  }

  .mobislider2 {
    scale: 0.8 !important;
    margin-top: 10px !important;
  }
  .mobislider1 {
    margin-top: 21px !important;
    scale: 0.9;
  }

  .storeiconmobi {
    text-align: center !important;
  }
  .speimgmobi {
    max-width: 40% !important;
    height: auto;
  }
  .speicontextmobi {
    text-align: center !important;
  }
  .bannername2 {
    text-align: center !important;
    text-transform: capitalize !important;
    letter-spacing: 5px !important;
    font-size: 35px !important;
    font-family: sans-serif !important;
    margin-left: 18px !important;
    font-weight: bold !important;
  }
  .bnertextmobi {
    font-size: 20px !important;
    text-align: center !important;
  }

  .bannername3 {
    text-align: center !important;
    /* text-transform: capitalize!important; */
    /* letter-spacing: 5px!important; */
    font-size: 40px !important;
    font-family: sans-serif !important;
    margin-left: 18px !important;
    /* font-weight: 700!important; */
  }
  .btnmobi {
    /* margin-top: 0; */
    /* margin-left: 45px !important; */
  }

  .postcard .postcard__preview-txt {
    text-align: center !important;
  }
  h3.aos-init.aos-animate {
    text-align: center !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    font-family: sans-serif !important;
  }
  .swiper.swiper-initialized.swiper-horizontal {
    height: 120px;
  }
  img.logonavbar.aos-init.aos-animate {
    height: 51px !important;
    scale: 0.9 !important;
  }
  h5.aos-init.aos-animate {
    color: white !important;
    text-align: center !important;
  }
  img.aos-init.aos-animate {
    height: 100px;
  }
  article.postcard.light.blue.aos-init.aos-animate {
    border-radius: 50px !important;
    height: auto !important;
  }
  article.postcard.light.red.aos-init.aos-animate {
    border-radius: 50px !important;
    height: auto !important;
  }
  article.postcard.light.green.aos-init.aos-animate {
    border-radius: 50px !important;
    height: auto !important;
  }
  h1.p-5.aos-init.aos-animate {
    text-align: center !important;
    color: white !important;
    padding: 0px !important;
    font-size: 18px !important;
    font-weight: 900;
    margin-bottom: 1rem !important;
  }

  h5.aos-init.aos-animate {
    margin-left: 0px !important;
    font-size: 13px !important;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    text-align: center;
    list-style: none;
  }
  .imagesContaineruk {
    width: 100% !important;
    height: 280px;
    position: initial;
    overflow: hidden;
  }
  .worksiconmobi {
    padding-left: 0px !important;
    width: 100%;
    justify-content: center;
    display: flex;
    padding-bottom: 1rem;
  }
  .imageDiv {
    left: 33vw !important;
  }
  .bannericondr {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .hero-slide-content h1 {
    color: #ffffff;
    font-size: 65px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
  }

  img.specialitiesiconmobi {
    margin-left: 1px !important;
    height: auto;
  }
  img.specialitiesiconmobinew {
    display: contents;
  }
  .col-md-11.pt-2 {
    text-align: center;
  }
  .contact-form.form.text-left.col-lg-8.col-sm-7.col-md-12 {
    margin-left: 0px !important;
  }
  .container.pb-90.pt-90 {
    text-align: center;
  }
  .fixmobi {
    height: 540px !important;
    background-color: slategray !important;
    background-size: auto !important;
  }

  .pricings .card {
    background: #51cada;
    border: solid 3px white;
    border-radius: 60px;

    box-shadow: 0 0.5rem 1rem 0 rgb(255 254 254 / 56%);
  }
  .mobimedical {
    font-size: 37px !important;
    font-weight: 600;
  }
  .mobimedicalnum {
    font-size: 55px !important;
  }

  p.aos-init.aos-animate {
    font-family: calibri;
    font-size: 18px;
    margin-top: -15px;
    padding-bottom: 2rem;
  }

  .mobimainicon {
    margin-top: 25px !important;
  }
  .mobitext {
    color: black;
    font-size: 10px;
    font-weight: 600;
  }
  .learnmorembi {
    background: rgb(50, 198, 218);
    border-radius: 20px;
    border: 1px solid rgb(50, 198, 218);
    font-size: 13px;
  }
  .itemmobi {
    height: 230px;
    margin-left: 40px;
  }

  .ratingbgmobi {
    border-radius: 70px;
    height: 0% !important;
  }
  .ratingtextmobi {
    text-align: center;
    font-size: 15px;
  }
  .ratingstarmobi {
    margin-left: 11px !important;
    margin-top: 1px !important;
  }
  .ratinglinemobi {
    display: none;
  }
  .ratingnametextmobi {
    margin-left: 114px !important;
    margin-top: -60px !important;
    color: rgb(50, 198, 218) !important;
    font-size: 18px !important;
  }
  .hearwhatmobi {
    text-align: center;
    letter-spacing: 1px !important;
    color: white;
    font-size: 17px !important;
  }
  .cardbodymobi {
    flex: 1 1 auto;
    padding: 0.25rem;
  }
  .ratingdpclinet {
    margin-top: 7px;
    margin-left: 0px;
    width: 86%;
  }
  .ratingnamtext {
    margin-left: 27px !important;
    color: rgb(50, 198, 218);
    letter-spacing: 1px;
    margin-top: -293px;
  }
  .ratingtextgirlmobi {
    text-align: center !important;
    margin-top: 100px !important;
    font-size: 13px;
  }
  .ratingbgcolr {
    width: 95px !important;
    height: 96px !important;
    border-radius: 73px !important;
    margin-left: 93px !important;
    margin-top: -114px !important;
  }
  .ratingclinetstar {
    width: 110px;
    margin-left: 42px;
    margin-top: 22px;
  }
  .mbrow{
    display: flex!important;
    gap: 0%!important;
    width: 100%!important;
    padding: 0;
  }
  .textfotermobi{
    font-size: 10px!important;
  }
}

.dpmobifoter {
  display: contents;
}

.btnbanner{
  margin-right: 46%;
}
