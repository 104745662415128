.fade-in-text {
    font-family: sans-serif;
    font-size: 60px;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


  
  @media only screen and (max-device-width: 530px){
.eightgoogle{
  border: 1px solid white;
  border-radius: 5px;
  scale: 0.5!important;
  margin-left: 0px!important;
  margin-top: -7%!important;
}
.eightapp{
  border: 1px solid white;
  border-radius: 5px;
  scale: 0.5!important;
  margin-top: -7%!important;
  margin-left: 0px!important;
}
.googlemobipicdiv{
  margin-top: 0px!important;
}
}



@media (min-width: 860px) and (max-width: 1199px) {
  .mobiiconapp{
    scale: 3.5!important;
  }
}
@media (min-width: 769px) and (max-width: 859px) {
  .mobiiconapp{
    scale: 4.5!important;
  }
}



