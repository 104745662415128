.hovhed:hover{
    /* text-shadow: 0 0 20px #fff; */
  transition: 0.5s!important;
  box-shadow: 0px 0px 25px #fff;
}
/* ---  */

/* .box{
  width:100px;
  height:100px;
  background:#000;
} */
.up-down {
  animation: up-down infinite 5s;
  transform-origin: 50% 50%;
}

@keyframes up-down {
  0% {
    transform: translate(1px, 20px);
  }
  24% {
    transform: translate(1px, 30px);
  }
  50% {
    transform: translate(1px, 15px);
  }
  74% {
    transform: translate(1px, 30px);
  }
  100% {
    transform: translate(1px, 20px);
  }
}

/* Optionally, you can include the prefixed versions for older browsers */

/* Example: -webkit-keyframes, -moz-keyframes, etc. */
