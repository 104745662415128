.ukyy::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}
.ukyy::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }

  .dfdestop{
    justify-content: center;
  }

  .center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form .input input[type="text"], .form .input input[type="email"], .form .input input[type="tel"], .form .input input[type="password"], .form .input input[type="search"], .form .input input[type="url"], .form .input textarea {
    border-color: #dddddd;
    border-style: solid;
    background-color: black!important;
    border-width: 0 0 1px;
    float: left;
    height: 32px;
    padding: 0 0 5px;
    width: 100%;
    border-radius: 10px;
    opacity: 0.7;
}


input.pl-2.ukyy::-moz-focus-inner {
  background-color: black!important;
}


div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -1.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.875em;
  left: 0.8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=long] {
  top: 1.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
div:where(.swal2-icon).swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -1.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165,220,134,.3);
  border-radius: 50%;
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -1.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 4.75em;
  height: 8.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}

::placeholder{
  color: white!important;
}